<!-- 升级会员支付方式弹窗 -->
<template>
  <div>
    <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="this.data"
      @update:visible="updateVisible">

      <el-form
        ref="form"
        :model="form"
        label-width="100px">

        <div v-if="this.data === '微信支付'">
          <div>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">您选择的是白金年卡会员,需</span>
            <span style="font-size: 20px;font-weight: 700;color: #FF9B05;">支付100元:</span>
          </div>
          <div style="margin-top: 20px;text-align: center;">
            <img src="@/assets/images/home/erweima.png" alt="" style="width: 200px;height: 200px;">
          </div>
          <div style="margin-top: 20px;text-align: center;">
            <span style="font-size: 14px;font-weight: 400;color: #666666;">请使用微信扫码支付</span>
          </div>
        </div>

        <div v-if="this.data === '支付宝支付'">
          <div>
            <span style="font-size: 20px;font-weight: 700;color: #202033;">您选择的是白金年卡会员,需</span>
            <span style="font-size: 20px;font-weight: 700;color: #FF9B05;">支付100元:</span>
          </div>
          <div style="margin-top: 20px;text-align: center;">
            <img src="@/assets/images/home/erweima.png" alt="" style="width: 200px;height: 200px;">
          </div>
          <div style="margin-top: 20px;text-align: center;">
            <span style="font-size: 14px;font-weight: 400;color: #666666;">请使用支付宝扫码支付</span>
          </div>
        </div>

        <!--银行汇款-->
        <div v-if="this.data === '银行汇款'">
          <el-form-item label="开户银行：">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.nickname"
              placeholder="请输入开户银行"/>
          </el-form-item>
          <el-form-item label="银行账号：">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.nickname"
              placeholder="请输入银行账号"/>
          </el-form-item>
          <el-form-item label="收款户名：">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.nickname"
              placeholder="请输入收款户名"/>
          </el-form-item>
          <el-form-item label="开户行号：">
            <el-input
              clearable
              :maxlength="20"
              v-model="form.nickname"
              placeholder="请输入开户行号"/>
          </el-form-item>
          <div style="margin-top: 20px;background: #f7f7f7;border-radius: 5px;padding: 10px;display: flex;align-items: center;">
            <img src="@/assets/images/home/gantanhao.png" alt="" style="width: 19px;height: 19px;margin-right: 10px;">
            <span style="font-size: 14px;font-weight: 400;color: #ff9b05;">转账后请联系客服核证</span>
          </div>
        </div>

      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          type="primary"
          @click="updateVisible(false)">确定
        </el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    console.log(this.form)
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">

</style>
